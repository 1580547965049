import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import { Member }           from "Utils/API";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The White List Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function WhiteListEdit(props) {
    const { open, elemID, activityID, onClose, onSubmit } = props;

    const { activities, statuses } = Store.useState("trainingWhiteList");
    const { editElem } = Store.useAction("trainingWhiteList");


    // The Initial Data
    const initialData = {
        trainingWhiteListID : 0,
        activityID          : 0,
        memberNumber        : "",
        memberName          : "",
        description         : "",
        status              : "Active",
    };

    // Handles the Edit
    const handleEdit = (data) => {
        if (activityID) {
            return editElem({ ...data, activityID });
        }
        return editElem({ ...data });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("trainingWhiteList", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("trainingWhiteList", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="training"
        title={elemID ? "TRAININGS_WHITE_LIST_EDIT_TITLE" : "TRAININGS_WHITE_LIST_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            type="suggest"
            name="memberName"
            label="GENERAL_MEMBER"
            value={data.memberName}
            error={errors.memberNumber}
            suggestID="memberNumber"
            suggestFetch={Member.search}
            onChange={handleChange}
            isRequired
        />
        <InputField
            isHidden={!!activityID}
            type="select"
            name="activityID"
            label="ACTIVITIES_SINGULAR"
            options={activities}
            value={data.activityID}
            error={errors.activityID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="textarea"
            name="description"
            label="GENERAL_DESCRIPTION"
            value={data.description}
            error={errors.description}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
WhiteListEdit.propTypes = {
    open       : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    elemID     : PropTypes.number,
    activityID : PropTypes.number,
};

export default WhiteListEdit;
