import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import SubscriptionView     from "./SubscriptionView";
import SubscriptionEdit     from "./SubscriptionEdit";

// Dashboard
import Filter               from "Dashboard/Components/Filter/Filter";
import FilterItem           from "Dashboard/Components/Filter/FilterItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



/**
 * The Course Subscription Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SubscriptionContent(props) {
    const { updates } = props;
    const { fetch, load, loadFilter } = useList("courseSubscription", "COURSE");

    const { courseID } = Navigate.useParams();

    const data = Store.useState("courseSubscription");
    const { loading, canEdit, list, total, statuses, filters, sort } = data;

    const { pendingElem, nullifyElem, deleteElem } = Store.useAction("courseSubscription");

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Fetch new data on update
    React.useEffect(() => {
        fetch();
    }, [ updates ]);

    // Handles the Edit Submit
    const handleEdit = () => {
        fetch();
        endAction();
    };

    // Handles the Pending Submit
    const handlePending = async () => {
        endAction();
        const result = await pendingElem(elemID);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Nullify Submit
    const handleNullify = async () => {
        endAction();
        const result = await nullifyElem(elemID);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };

    // Handles the Close
    const handleClose = (update) => {
        endAction();
        if (update) {
            fetch();
        }
    };

    // Returns true if the Pending is hidden
    const hidePending = (elemID) => {
        const status = Utils.getValue(list, "subscriptionID", elemID, "status");
        return status !== "Nullified";
    };

    // Returns true if the Nullify is hidden
    const hideNullify = (elemID) => {
        const status = Utils.getValue(list, "subscriptionID", elemID, "status");
        return status === "Nullified";
    };


    // Do the Render
    const elemName = Utils.getValue(list, "subscriptionID", elemID, "name");

    return <>
        <Filter
            onFilter={loadFilter}
            values={filters}
            hasSearch
        >
            <FilterItem
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
            />
        </Filter>

        <Table
            fetch={load}
            sort={sort}
            none="SUBSCRIPTIONS_NONE_AVAILABLE"
            isLoading={loading}
            hasTabs
            hasFilter
        >
            <TableHead>
                <TableHeader field="createdTime"  message="GENERAL_CREATED_AT_FEM" minWidth="160" maxWidth="160" isTitle />
                <TableHeader field="number"       message="GENERAL_NUMBER"         maxWidth="100" />
                <TableHeader field="memberNumber" message="GENERAL_MEMBER_NUMBER"  maxWidth="120" />
                <TableHeader field="firstName"    message="GENERAL_MEMBER"         />
                <TableHeader field="totalPersons" message="COURSES_PERSONS_NAME"   maxWidth="100" align="center" />
                <TableHeader field="status"       message="GENERAL_STATUS"         maxWidth="100" align="center" />
            </TableHead>
            <TableBody>
                {list.map((elem) => <TableRow key={elem.subscriptionID} elemID={elem.subscriptionID}>
                    <TableCell message={elem.createdTimeText} />
                    <TableCell message={elem.numberText}      />
                    <TableCell message={elem.memberNumber}    />
                    <TableCell message={elem.memberName}      />
                    <TableCell message={elem.personsText}     />
                    <TableCell message={elem.statusName}      textColor={elem.statusColor} />
                </TableRow>)}
            </TableBody>
            <TablePaging total={total} />
            <TableActionList onAction={startAction} canEdit={canEdit}>
                <TableAction action="VIEW"    message="SUBSCRIPTIONS_VIEW_TITLE"    />
                <TableAction action="EDIT"    message="SUBSCRIPTIONS_EDIT_TITLE"    />
                <TableAction action="PENDING" message="SUBSCRIPTIONS_PENDING_TITLE" hide={hidePending} />
                <TableAction action="NULLIFY" message="SUBSCRIPTIONS_NULLIFY_TITLE" hide={hideNullify} />
                <TableAction action="DELETE"  message="SUBSCRIPTIONS_DELETE_TITLE"  />
            </TableActionList>
        </Table>

        <SubscriptionView
            open={action.isView}
            elemID={elemID}
            onClose={handleClose}
        />
        <SubscriptionEdit
            open={action.isEdit}
            elemID={elemID}
            courseID={courseID}
            onSubmit={handleEdit}
            onClose={handleClose}
        />
        <ConfirmDialog
            open={action.isPending}
            icon="pending"
            title="SUBSCRIPTIONS_PENDING_TITLE"
            message="SUBSCRIPTIONS_PENDING_TEXT"
            content={elemName}
            onSubmit={handlePending}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isNullify}
            icon="nullify"
            title="SUBSCRIPTIONS_NULLIFY_TITLE"
            message="SUBSCRIPTIONS_NULLIFY_TEXT"
            primary="GENERAL_NULLIFY"
            content={elemName}
            onSubmit={handleNullify}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="SUBSCRIPTIONS_DELETE_TITLE"
            message="SUBSCRIPTIONS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SubscriptionContent.propTypes = {
    updates : PropTypes.number.isRequired,
};

export default SubscriptionContent;
