import React                from "react";

// Components
import AdminList            from "Components/App/Setup/Admin/AdminList";
import ProvinceList         from "Components/App/Setup/Province/ProvinceList";
import VersionList          from "Components/App/Setup/Version/VersionList";
import MediaPage            from "Components/App/Setup/Media/MediaPage";
import NotificationTypeList from "Components/App/Setup/NotificationType/NotificationTypeList";
import NotificationList     from "Components/App/Setup/Notification/NotificationList";
import EmailTemplateList    from "Components/App/Email/EmailTemplate/EmailTemplateList";
import EmailQueueList       from "Components/App/Email/EmailQueue/EmailQueueList";
import EmailWhiteList       from "Components/App/Email/EmailWhiteList/EmailWhiteList";
import ProviderLogList      from "Components/App/Log/ProviderLog/ProviderLogList";
import ActionLogList        from "Components/App/Log/ActionLog/ActionLogList";
import ErrorLogList         from "Components/App/Log/ErrorLog/ErrorLogList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Setup Container
 * @returns {React.ReactElement}
 */
function SetupContainer() {
    return <>
        <SecondaryNav icon="settings" message="GENERAL_SETTINGS">
            <NavigationList>
                <NavigationItem
                    message="ADMINS_NAME"
                    url="ADMINS"
                    icon="admin"
                />
                <NavigationItem
                    message="PROVINCES_NAME"
                    url="PROVINCES"
                    icon="province"
                />
                <NavigationItem
                    message="VERSIONS_NAME"
                    url="VERSIONS"
                    icon="version"
                />
                <NavigationItem
                    message="MEDIA_NAME"
                    url="MEDIA"
                    icon="media"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="NOTIFICATION_TYPES_NAME"
                    url="NOTIFICATION_TYPES"
                    icon="notification-type"
                />
                <NavigationItem
                    message="NOTIFICATIONS_NAME"
                    url="NOTIFICATIONS"
                    icon="notification"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="EMAIL_TEMPLATES_NAME"
                    url="EMAIL_TEMPLATES"
                    icon="email"
                />
                <NavigationItem
                    message="EMAIL_QUEUE_NAME"
                    url="EMAIL_QUEUE"
                    icon="email-queue"
                />
                <NavigationItem
                    message="EMAIL_WHITE_LIST_NAME"
                    url="EMAIL_WHITE_LIST"
                    icon="white-email"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="PROVIDERS_LOG_NAME"
                    url="LOG_PROVIDERS"
                    icon="provider-log"
                />
                <NavigationItem
                    message="ACTIONS_LOG_NAME"
                    url="LOG_ACTIONS"
                    icon="action-log"
                />
                <NavigationItem
                    message="ERRORS_LOG_NAME"
                    url="LOG_ERRORS"
                    icon="error-log"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="ADMINS"             component={AdminList}            />
            <AdminRoute url="PROVINCES"          component={ProvinceList}         />
            <AdminRoute url="VERSIONS"           component={VersionList}          />
            <AdminRoute url="MEDIA"              component={MediaPage}            />
            <AdminRoute url="NOTIFICATION_TYPES" component={NotificationTypeList} />
            <AdminRoute url="NOTIFICATIONS"      component={NotificationList}     />
            <AdminRoute url="EMAIL_TEMPLATES"    component={EmailTemplateList}    />
            <AdminRoute url="EMAIL_QUEUE"        component={EmailQueueList}       />
            <AdminRoute url="EMAIL_WHITE_LIST"   component={EmailWhiteList}       />
            <AdminRoute url="LOG_PROVIDERS"      component={ProviderLogList}      />
            <AdminRoute url="LOG_ACTIONS"        component={ActionLogList}        />
            <AdminRoute url="LOG_ERRORS"         component={ErrorLogList}         />
        </Router>
    </>;
}

export default SetupContainer;
