import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import useMedia             from "Dashboard/Hooks/Media";

// Components
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";
import EditorField          from "Dashboard/Components/Form/EditorField";



/**
 * The Course Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CourseEdit(props) {
    const { open, isCopy, elemID, activityID, onClose, onSubmit } = props;

    const { statuses } = Store.useState("course");
    const { editElem } = Store.useAction("course");


    // The Current State
    const [ tab, setTab ] = React.useState("info");

    // The Initial Data
    const initialData = {
        courseID          : 0,
        activityID        : 0,
        name              : "",
        availableFromDate : "",
        availableFromHour : "",
        availableToDate   : "",
        availableToHour   : "",
        fromDate          : "",
        toDate            : "",
        content           : "",
        emailContent      : "",
        questions         : "[]",
        groups            : "[{}]",
        status            : "Active",
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({
            ...elem,
            questions : elem?.questionsList?.length ? JSON.stringify(elem.questionsList) : "[]",
            groups    : elem?.groups?.length        ? JSON.stringify(elem.groups)        : "[{}]",
        });
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await editElem({
            ...data,
            courseID : isCopy ? 0 : (elemID || 0),
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("course", initialData, handleEdit, onSubmit);

    // The Media State
    const {
        showMedia, mediaType, handleMediaOpen, handleMediaSubmit, handleMediaClose,
    } = useMedia();

    // Load the Data
    const { loading } = useDialog("course", open, elemID, { activityID }, handleSet);


    // Returns the Error Count in the Step
    const getErrorCount = (tab) => {
        let total = 0;
        for (const [ key, error ] of Object.entries(errors)) {
            if (!error) {
                continue;
            }
            if (tab === "content" && key === "content") {
                total += 1;
            } else if (tab === "info" && key !== "content") {
                total += 1;
            }
        }
        return total;
    };

    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "COURSES_COPY_TITLE";
        }
        if (elemID) {
            return "COURSES_EDIT_TITLE";
        }
        return "COURSES_CREATE_TITLE";
    }, [ isCopy, elemID ]);


    // Do the Render
    return <>
        <EditDialog
            open={open}
            icon="course"
            title={title}
            error={errors.form}
            onSubmit={handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <TabList selected={tab} onClick={setTab} inDialog>
                <TabItem message="GENERAL_INFO"      value="info"      badge={getErrorCount("info")}      />
                <TabItem message="COURSES_QUESTIONS" value="questions" badge={getErrorCount("questions")} />
                <TabItem message="COURSES_GROUPS"    value="groups"    badge={getErrorCount("groups")}    />
                <TabItem message="GENERAL_CONTENT"   value="content"   badge={getErrorCount("content")}   />
                <TabItem message="GENERAL_EMAIL"     value="email"     badge={getErrorCount("email")}     />
            </TabList>

            <Columns isHidden={tab !== "info"}>
                <InputField
                    className="columns-double"
                    name="name"
                    label="GENERAL_NAME"
                    value={data.name}
                    error={errors.name}
                    onChange={handleChange}
                    isRequired
                />

                <InputField
                    type="date"
                    name="availableFromDate"
                    label="GENERAL_AVAILABLE_FROM_DATE"
                    value={data.availableFromDate}
                    error={errors.availableFromDate}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="time"
                    name="availableFromHour"
                    label="GENERAL_AVAILABLE_FROM_HOUR"
                    value={data.availableFromHour}
                    error={errors.availableFromHour}
                    onChange={handleChange}
                    isRequired
                />

                <InputField
                    type="date"
                    name="availableToDate"
                    label="GENERAL_AVAILABLE_TO_DATE"
                    value={data.availableToDate}
                    error={errors.availableToDate}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="time"
                    name="availableToHour"
                    label="GENERAL_AVAILABLE_TO_HOUR"
                    value={data.availableToHour}
                    error={errors.availableToHour}
                    onChange={handleChange}
                    isRequired
                />

                <InputField
                    type="date"
                    name="fromDate"
                    label="COURSES_FROM_DATE"
                    value={data.fromDate}
                    error={errors.fromDate}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="date"
                    name="toDate"
                    label="COURSES_TO_DATE"
                    value={data.toDate}
                    error={errors.toDate}
                    onChange={handleChange}
                    isRequired
                />

                <InputField
                    className="columns-double"
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                    value={data.status}
                    error={errors.status}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
            </Columns>

            <InputField
                isHidden={tab !== "questions"}
                type="list"
                name="questions"
                label="COURSES_QUESTIONS"
                addButton="COURSES_ADD_QUESTION"
                value={data.questions}
                errors={errors}
                onChange={handleChange}
                isSortable
            />
            <InputField
                isHidden={tab !== "groups"}
                type="fields"
                name="groups"
                title="COURSES_GROUPS_TITLE"
                addButton="COURSES_ADD_GROUP"
                value={data.groups}
                errors={errors}
                onChange={handleChange}
                columns="4"
                isSortable
            >
                <InputItem
                    columns="3"
                    name="name"
                    label="GENERAL_NAME"
                    isRequired
                />
                <InputItem
                    name="amount"
                    label="GENERAL_QUANTITY"
                    isDisabled
                />
            </InputField>

            <EditorField
                isHidden={tab !== "content"}
                name="content"
                value={data.content}
                error={errors.content}
                onChange={handleChange}
                onMedia={handleMediaOpen}
            />
            <EditorField
                isHidden={tab !== "email"}
                name="emailContent"
                value={data.emailContent}
                error={errors.emailContent}
                onChange={handleChange}
                onMedia={handleMediaOpen}
            />
        </EditDialog>

        <MediaDialog
            open={showMedia}
            mediaType={mediaType}
            onSubmit={handleMediaSubmit}
            onClose={handleMediaClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CourseEdit.propTypes = {
    open       : PropTypes.bool.isRequired,
    isCopy     : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    elemID     : PropTypes.number,
    activityID : PropTypes.number,
};

export default CourseEdit;
