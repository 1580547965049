// The App Actions
const actions = [
    {
        name    : "PENDING",
        icon    : "pending",
    },
    {
        name    : "NULLIFY",
        icon    : "nullify",
    },
    {
        name    : "FILE",
        icon    : "file",
    },
    {
        name    : "RESEND",
        icon    : "send",
    },
    {
        name    : "PRINT",
        icon    : "print",
        message : "GENERAL_PRINT",
    },


    // Reserve Actions
    {
        name : "BLOCK",
        icon : "court-block",
    },
    {
        name : "RESERVE",
        icon : "reserve",
    },


    // Ticket Actions
    {
        name    : "OPEN",
        icon    : "status",
        message : "GENERAL_OPEN",
    },
    {
        name    : "WAIT",
        icon    : "wait",
        message : "GENERAL_WAIT",
    },
    {
        name    : "CLOSE",
        icon    : "status",
        message : "GENERAL_CLOSE",
    },


    // Member Actions
    {
        name : "REGISTER",
        icon : "check",
    },
    {
        name : "UNREGISTER",
        icon : "close",
    },
];

export default actions;
