import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import useMedia             from "Dashboard/Hooks/Media";

// Components
import MediaDialog          from "Components/App/Setup/Media/MediaDialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import EditorField          from "Dashboard/Components/Form/EditorField";



/**
 * The Shift Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ShiftEdit(props) {
    const { open, isCopy, elemID, activityID, onClose, onSubmit } = props;

    const { statuses } = Store.useState("shift");
    const { editElem } = Store.useAction("shift");


    // The Current State
    const [ tab, setTab ] = React.useState("info");

    // The Initial Data
    const initialData = {
        shiftID           : 0,
        activityID        : 0,
        name              : "",
        date              : "",
        fromHour          : "",
        toHour            : "",
        amount            : "",
        extraAmount       : "",
        availableFromDate : "",
        availableFromHour : "",
        availableToDate   : "",
        availableToHour   : "",
        content           : "",
        status            : "Active",
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await editElem({
            ...data,
            shiftID : isCopy ? 0 : (elemID || 0),
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("shift", initialData, handleEdit, onSubmit);

    // The Media State
    const {
        showMedia, mediaType, handleMediaOpen, handleMediaSubmit, handleMediaClose,
    } = useMedia();

    // Load the Data
    const { loading } = useDialog("shift", open, elemID, { activityID }, setElem);


    // Returns the Error Count in the Step
    const getErrorCount = (tab) => {
        let total = 0;
        for (const [ key, error ] of Object.entries(errors)) {
            if (!error) {
                continue;
            }
            if (tab === "content" && key === "content") {
                total += 1;
            } else if (tab === "info" && key !== "content") {
                total += 1;
            }
        }
        return total;
    };

    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "SHIFTS_COPY_TITLE";
        }
        if (elemID) {
            return "SHIFTS_EDIT_TITLE";
        }
        return "SHIFTS_CREATE_TITLE";
    }, [ isCopy, elemID ]);


    // Do the Render
    return <>
        <EditDialog
            open={open}
            icon="shift"
            title={title}
            error={errors.form}
            onSubmit={handleSubmit}
            onClose={onClose}
            isLoading={loading}
        >
            <TabList selected={tab} onClick={setTab} inDialog>
                <TabItem message="GENERAL_INFO"    value="info"    badge={getErrorCount("info")}    />
                <TabItem message="GENERAL_CONTENT" value="content" badge={getErrorCount("content")} />
            </TabList>

            <Columns isHidden={tab !== "info"}>
                <InputField
                    className="columns-double"
                    name="name"
                    label="GENERAL_NAME"
                    value={data.name}
                    error={errors.name}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    className="columns-double"
                    type="date"
                    name="date"
                    label="GENERAL_DATE"
                    value={data.date}
                    error={errors.date}
                    onChange={handleChange}
                    isRequired
                />

                <InputField
                    type="select"
                    name="fromHour"
                    label="GENERAL_FROM_HOUR"
                    options={DateTime.getHourSelect()}
                    value={data.fromHour}
                    error={errors.fromHour}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="select"
                    name="toHour"
                    label="GENERAL_TO_HOUR"
                    options={DateTime.getHourSelect()}
                    value={data.toHour}
                    error={errors.toHour}
                    onChange={handleChange}
                    isRequired
                />

                <InputField
                    type="number"
                    name="amount"
                    label="SHIFTS_AMOUNT"
                    value={data.amount}
                    error={errors.amount}
                    onChange={handleChange}
                    minValue={0}
                    step={1}
                    isRequired
                />
                <InputField
                    type="number"
                    name="extraAmount"
                    label="SHIFTS_EXTRA_AMOUNT"
                    value={data.extraAmount}
                    error={errors.extraAmount}
                    onChange={handleChange}
                    minValue={0}
                    step={1}
                    isRequired
                />

                <InputField
                    type="date"
                    name="availableFromDate"
                    label="GENERAL_AVAILABLE_FROM_DATE"
                    value={data.availableFromDate}
                    error={errors.availableFromDate}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="time"
                    name="availableFromHour"
                    label="GENERAL_AVAILABLE_FROM_HOUR"
                    value={data.availableFromHour}
                    error={errors.availableFromHour}
                    onChange={handleChange}
                    isRequired
                />

                <InputField
                    type="date"
                    name="availableToDate"
                    label="GENERAL_AVAILABLE_TO_DATE"
                    value={data.availableToDate}
                    error={errors.availableToDate}
                    onChange={handleChange}
                    isRequired
                />
                <InputField
                    type="time"
                    name="availableToHour"
                    label="GENERAL_AVAILABLE_TO_HOUR"
                    value={data.availableToHour}
                    error={errors.availableToHour}
                    onChange={handleChange}
                    isRequired
                />

                <InputField
                    className="columns-double"
                    type="select"
                    name="status"
                    label="GENERAL_STATUS"
                    options={statuses}
                    value={data.status}
                    error={errors.status}
                    onChange={handleChange}
                    hideClear
                    isRequired
                />
            </Columns>

            <EditorField
                isHidden={tab !== "content"}
                name="content"
                value={data.content}
                error={errors.content}
                onChange={handleChange}
                onMedia={handleMediaOpen}
            />
        </EditDialog>

        <MediaDialog
            open={showMedia}
            mediaType={mediaType}
            onSubmit={handleMediaSubmit}
            onClose={handleMediaClose}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ShiftEdit.propTypes = {
    open       : PropTypes.bool.isRequired,
    isCopy     : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    elemID     : PropTypes.number,
    activityID : PropTypes.number,
};

export default ShiftEdit;
