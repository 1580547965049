import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";
import { Member }           from "Utils/API";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import Utils from "Dashboard/Utils/Utils";



/**
 * The Ticket Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TicketEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { canAssign, areas, departments, subjects, credentials } = Store.useState("ticket");
    const { createTicket, editTicket } = Store.useAction("ticket");


    // The Initial Data
    const initialData = {
        ticketID           : 0,
        ticketAreaID       : 0,
        ticketDepartmentID : 0,
        ticketSubjectID    : 0,
        credentialID       : 0,
        priority           : "",
        memberNumber       : "",
        memberName         : "",
        message            : "",
        file               : null,
        fileName           : "",
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        if (!elemID) {
            await createTicket(data);
        } else {
            await editTicket(data);
        }
    };

    // Handles the Update
    const handleUpdate = (name, value, secName, secValue) => {
        switch (name) {
        case "ticketAreaID":
            setData({
                ticketAreaID       : value,
                ticketDepartmentID : 0,
                ticketSubjectID    : 0,
            });
            break;
        case "ticketDepartmentID":
            setData({
                ticketDepartmentID : value,
                ticketSubjectID    : 0,
            });
            break;
        case "ticketSubjectID":
            if (value) {
                const list = subjects[data.ticketDepartmentID] ?? [];
                setData({
                    ticketSubjectID : value,
                    message         : Utils.getValue(list, "key", value, "message"),
                });
            } else {
                handleChange(name, value);
            }
            break;
        default:
            handleChange(name, value, secName, secValue);
        }
    };

    // The Form State
    const {
        data, errors, setElem, setError, setData, handleChange, handleSubmit,
    } = useForm("ticket", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("ticket", open, elemID, null, setElem);



    // Do the Render
    const isEdit = Boolean(elemID);

    return <EditDialog
        open={open}
        icon="ticket"
        title={elemID ? "TICKETS_EDIT_TITLE" : "TICKETS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="select"
            name="priority"
            label="GENERAL_PRIORITY"
            options="SELECT_PRIORITIES"
            value={data.priority}
            error={errors.priority}
            onChange={handleUpdate}
            isRequired
        />
        <InputField
            type="select"
            name="ticketAreaID"
            label="TICKET_AREAS_SINGULAR"
            options={areas}
            value={data.ticketAreaID}
            error={errors.ticketAreaID}
            onChange={handleUpdate}
            isRequired
        />
        <InputField
            type="select"
            name="ticketDepartmentID"
            label="TICKET_DEPARTMENTS_SINGULAR"
            options={departments[data.ticketAreaID] ?? []}
            value={data.ticketDepartmentID}
            error={errors.ticketDepartmentID}
            onChange={handleUpdate}
            isRequired
        />
        <InputField
            isHidden={!canAssign}
            type="select"
            name="credentialID"
            label="TICKETS_ASSIGNED"
            options={credentials}
            value={data.credentialID}
            error={errors.credentialID}
            onChange={handleUpdate}
        />
        <InputField
            isHidden={isEdit}
            type="suggest"
            name="memberName"
            label="GENERAL_MEMBER"
            value={data.memberName}
            error={errors.memberNumber}
            suggestID="memberNumber"
            suggestFetch={Member.search}
            onChange={handleUpdate}
            isRequired
        />
        <InputField
            type="select"
            name="ticketSubjectID"
            label="TICKET_SUBJECTS_SINGULAR"
            options={subjects[data.ticketDepartmentID] ?? []}
            value={data.ticketSubjectID}
            error={errors.ticketSubjectID}
            onChange={handleUpdate}
            isRequired
        />
        <InputField
            isHidden={isEdit}
            type="textarea"
            name="message"
            label="GENERAL_MESSAGE"
            value={data.message}
            error={errors.message}
            onChange={handleUpdate}
            isRequired
        />
        <InputField
            isHidden={isEdit}
            type="file"
            name="file"
            label="GENERAL_FILE"
            value={data.fileName}
            error={errors.file}
            onChange={handleUpdate}
            onError={setError}
            maxSize={3}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TicketEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default TicketEdit;
