const actions = {

    // Auth
    Session : {
        name    : "Sesión",
        actions : {
            Login      : "Inicio de sesión",
            LoginAs    : "Inicio como usuario",
            Logout     : "Cierre de sesión",
            LogoutAs   : "Cierre como usuario",
            Recover    : "Recupero de contraseña",
            ResetPass  : "Contraseña modificada",
            ChangePass : "Contraseña cambiada",
            Disconnect : "Desconectar dispositivo",
        },
    },
    Profile : {
        name    : "Perfil",
        actions : {
            Edit   : "Cuenta editada",
            Upload : "Avatar subido",
            Delete : "Cuenta eliminada",
        },
    },



    // Entries
    News : {
        name    : "Novedad",
        actions : {
            Create : "Novedad creado",
            Edit   : "Novedad editado",
            Delete : "Novedad eliminado",
        },
    },
    NewsCategory : {
        name    : "Categoría de novedad",
        actions : {
            Create : "Categoría creada",
            Edit   : "Categoría editada",
            Delete : "Categoría eliminada",
        },
    },
    Billboard : {
        name    : "Cartelera",
        actions : {
            Create : "Entrada creada",
            Edit   : "Entrada editada",
            Delete : "Entrada eliminada",
        },
    },



    // Application
    Account : {
        name    : "Cuenta",
        actions : {
            EditAddress   : "Dirección editada",
            EditEmail     : "Email editado",
            EditCellphone : "Celular editado",
            EditPhone     : "Teléfono editado",
            EditPassword  : "Contraseña editada",
            SaveInterests : "Intereses guardados",
        },
    },
    Invitation : {
        name    : "Invitación",
        actions : {
            Create  : "Invitación creada",
            Edit    : "Invitación editada",
            Nullify : "Invitación anulada",
        },
    },
    Event : {
        name    : "Evento",
        actions : {
            Create : "Entrada de evento comprada",
            Edit   : "Entrada de evento editada",
        },
    },
    Pool : {
        name    : "Abono de pileta",
        actions : {
            Create : "Abono de pileta creado",
        },
    },



    // Activity
    Activity : {
        name    : "Actividades",
        actions : {
            Create : "Actividad creada",
            Edit   : "Actividad editada",
            Delete : "Actividad eliminada",
        },
    },
    Button : {
        name    : "Botón",
        actions : {
            Create : "Botón creado",
            Edit   : "Botón editado",
            Delete : "Botón eliminado",
        },
    },
    Page : {
        name    : "Página",
        actions : {
            Create : "Página creada",
            Edit   : "Página editada",
            Delete : "Página eliminada",
        },
    },



    // Activity: Course
    Course : {
        name    : "Curso",
        actions : {
            Create  : "Curso creado",
            Edit    : "Curso editado",
            Confirm : "Curso confirmado",
            Delete  : "Curso eliminado",
        },
    },
    CourseSubscription : {
        name    : "Suscripción a curso",
        actions : {
            Create      : "Suscripción a curso creada",
            Edit        : "Suscripción a curso editada",
            MarkPending : "Suscripción a curso marcada como pendiente",
            Nullify     : "Suscripción a curso anulada",
            Delete      : "Suscripción a curso eliminada",
            Export      : "Suscripciones a curso exportadas",
            EditPerson  : "Niño/a editada en suscripción",
            MultiPerson : "Niños/as editadas en suscripción",
        },
    },



    // Activity: Shift
    Shift : {
        name    : "Turno",
        actions : {
            Create : "Turno creado",
            Edit   : "Turno editado",
            Delete : "Turno eliminado",
        },
    },
    ShiftSubscription : {
        name    : "Suscripción a turno",
        actions : {
            Reserve   : "Reserva a turno creada",
            Unreserve : "Reserva a turno eliminada",
            Create    : "Suscripción a turno creada",
            Edit      : "Suscripción a turno editada",
            Nullify   : "Suscripción a turno anulada",
            Export    : "Suscripciones a turno exportadas",
        },
    },
    ShiftCourse : {
        name    : "Curso de turno",
        actions : {
            Create : "Curso de turno creado",
            Edit   : "Curso de turno editado",
            Delete : "Curso de turno eliminado",
        },
    },



    // Activity: Training
    Training : {
        name    : "Entrenamiento",
        actions : {
            Create : "Entrenamiento creado",
            Edit   : "Entrenamiento editado",
            Delete : "Entrenamiento eliminado",
        },
    },
    TrainingSubscription : {
        name    : "Suscripción a entrenamiento",
        actions : {
            Create : "Suscripción a entrenamiento creada",
            Edit   : "Suscripción a entrenamiento editada",
            Delete : "Suscripción a entrenamiento eliminada",
            Export : "Suscripciones a entrenamiento exportadas",
        },
    },
    TrainingWhiteList : {
        name    : "Lista blanca de entrenamiento",
        actions : {
            Create : "Socio agregado a lista blanca",
            Edit   : "Socio editado en lista blanca",
            Delete : "Socio quitado de lista blanca",
        },
    },



    // Reserve
    Reserve : {
        name    : "Reserva",
        actions : {
            Create  : "Reserva creada",
            Edit    : "Reserva editada",
            Confirm : "Reserva confirmada",
            Nullify : "Reserva anulada",
        },
    },
    ReserveType : {
        name    : "Tipo de reserva",
        actions : {
            Create : "Tipo de reserva creado",
            Edit   : "Tipo de reserva editado",
            Delete : "Tipo de reserva eliminado",
        },
    },
    Court : {
        name    : "Cancha",
        actions : {
            Create : "Cancha creada",
            Edit   : "Cancha editada",
            Delete : "Cancha eliminada",
        },
    },
    CourtBlock : {
        name    : "Bloqueo de cancha",
        actions : {
            Create : "Bloqueo de cancha creado",
            Edit   : "Bloqueo de cancha editado",
            Delete : "Bloqueo de cancha eliminado",
        },
    },
    CourtBlockType : {
        name    : "Tipo de bloqueo",
        actions : {
            Create : "Tipo de bloqueo creado",
            Edit   : "Tipo de bloqueo editado",
            Delete : "Tipo de bloqueo eliminado",
        },
    },



    // Schedule
    Schedule : {
        name    : "Horario",
        actions : {
            Create : "Horario creado",
            Edit   : "Horario editado",
            Delete : "Horario eliminado",
        },
    },
    ScheduleCategory : {
        name    : "Categoría de horario",
        actions : {
            Create : "Categoría creada",
            Edit   : "Categoría editada",
            Delete : "Categoría eliminada",
        },
    },
    ScheduleHoliday : {
        name    : "Feriados",
        actions : {
            Create : "Feriado creado",
            Edit   : "Feriado editado",
            Delete : "Feriado eliminado",
        },
    },



    // Administration
    Title : {
        name    : "Título",
        actions : {
            Edit : "Título editado",
        },
    },
    Fee : {
        name    : "Tarifa",
        actions : {
            Create : "Tarifa creado",
            Edit   : "Tarifa editado",
            Delete : "Tarifa eliminado",
        },
    },
    Balance : {
        name    : "Balance",
        actions : {
            Create : "Balance creado",
            Edit   : "Balance editado",
            Delete : "Balance eliminado",
        },
    },



    // Contact
    Contact : {
        name    : "Contacto",
        actions : {
            Create : "Contacto creado",
            Edit   : "Contacto editado",
            Delete : "Contacto eliminado",
        },
    },
    ContactCategory : {
        name    : "Categoría de contacto",
        actions : {
            Create : "Categoría creada",
            Edit   : "Categoría editada",
            Delete : "Categoría eliminada",
        },
    },
    ContactSocial : {
        name    : "Red social",
        actions : {
            Create : "Red social creada",
            Edit   : "Red social editada",
            Delete : "Red social eliminada",
        },
    },



    // Ticket
    Ticket : {
        name    : "Gestión",
        actions : {
            Create        : "Gestión creada",
            Edit          : "Gestión editada",
            Delete        : "Gestión eliminada",
            Assign        : "Gestión asignada",
            Open          : "Gestión abierta",
            Close         : "Gestión cerrada",
            Mute          : "Gestión silenciada",
            Unmute        : "Gestión activada",

            MessageAdd    : "Mensaje agregado",
            MessageEdit   : "Mensaje editado",
            MessageDelete : "Mensaje eliminado",
            MessageFile   : "Archivo eliminado",
        },
    },
    TicketArea : {
        name    : "Área de gestión",
        actions : {
            Create : "Área creada",
            Edit   : "Área editada",
            Delete : "Área eliminada",
        },
    },
    TicketDepartment : {
        name    : "Departamento de gestión",
        actions : {
            Create : "Departamento creado",
            Edit   : "Departamento editado",
            Delete : "Departamento eliminado",
        },
    },
    TicketSubject : {
        name    : "Tema de gestión",
        actions : {
            Create : "Tema creado",
            Edit   : "Tema editado",
            Delete : "Tema eliminado",
        },
    },
    TicketWaitlist : {
        name    : "Lista de espera de gestión",
        actions : {
            Create : "Lista de espera creada",
            Edit   : "Lista de espera editada",
            Delete : "Lista de espera eliminada",
        },
    },



    // Member
    Member : {
        name    : "Socio",
        actions : {
            Register   : "Socio marcado como registrado",
            Unregister : "Socio marcado como no registrado",
        },
    },
    MemberState : {
        name    : "Estado de socio",
        actions : {
            Create : "Estado de socio creado",
            Edit   : "Estado de socio editado",
            Delete : "Estado de socio eliminado",
        },
    },
    MemberCategory : {
        name    : "Categoría de socio",
        actions : {
            Create : "Categoría de socio creada",
            Edit   : "Categoría de socio editada",
            Delete : "Categoría de socio eliminada",
        },
    },



    // Documentation
    Document : {
        name    : "Documentation",
        actions : {
            Create : "Document created",
            Edit   : "Document edited",
            Delete : "Document deleted",
        },
    },



    // Setup
    Admin : {
        name    : "Administradores",
        actions : {
            Create : "Administrador creado",
            Edit   : "Administrador editado",
            Delete : "Administrador eliminado",
        },
    },
    Province : {
        name    : "Provincias",
        actions : {
            Create : "Provincia creada",
            Edit   : "Provincia editada",
            Delete : "Provincia eliminada",
        },
    },
    Version : {
        name    : "Versiones",
        actions : {
            Create : "Versión creada",
            Edit   : "Versión editada",
            Delete : "Versión eliminada",
        },
    },
    Media : {
        name    : "Archivo",
        actions : {
            Create : "Directorio creado",
            Upload : "Archivo subido",
            Rename : "Archivo renombrado",
            Move   : "Archivo movido",
            Delete : "Archivo eliminado",
        },
    },
    NotificationType : {
        name    : "Tipos de notificaciones",
        actions : {
            Create : "Tipo de notificación creado",
            Edit   : "Tipo de notificación editado",
            Delete : "Tipo de notificación eliminado",
        },
    },



    // Email
    EmailWhiteList : {
        name    : "Lista blanca de email",
        actions : {
            Add    : "Email agregado",
            Edit   : "Email editado",
            Remove : "Email quitado",
        },
    },
    EmailQueue : {
        name    : "Cola de email",
        actions : {
            Resend : "Email reenviado",
        },
    },



    // Log
    ErrorLog : {
        name    : "Log de error",
        actions : {
            Resolved : "Error resuelto",
            Delete   : "Error eliminado",
        },
    },
};

export default actions;
