import React                from "react";
import PropTypes            from "prop-types";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";

// Components
import TrainingContent      from "./Training/TrainingContent";
import WhiteListContent     from "./WhiteList/WhiteListContent";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import Content              from "Dashboard/Components/Core/Content";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



/**
 * The Training Container
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TrainingContainer(props) {
    const { type } = props;

    const { activityID, trainingTab } = Navigate.useParams();
    const navigate = Navigate.useGoto();


    // The Current State
    const [ isCreating, setCreating ] = React.useState(false);


    // Handle the Tab
    React.useEffect(() => {
        if (!trainingTab) {
            navigate("ACTIVITIES", activityID, "TRAINING_PAGE", "TRAININGS");
        }
    }, [ trainingTab ]);

    // Handles the Tab
    const handleTab = (url) => {
        navigate("ACTIVITIES", activityID, "TRAINING_PAGE", url);
    };


    // Variables
    const isTrainings = Navigate.isUrl("TRAININGS", trainingTab);
    const isWhiteList = Navigate.isUrl("WHITE_LIST", trainingTab);


    // Do the Render
    return <Main>
        <Header message="TRAININGS_NAME" icon="training">
            <ActionList>
                <ActionItem
                    variant="outlined"
                    icon="add"
                    message="GENERAL_CREATE"
                    onClick={() => setCreating(true)}
                />
            </ActionList>
        </Header>

        <Content className="light-scrollbars">
            <TabList
                selected={trainingTab || NLS.url("TRAININGS")}
                onClick={handleTab}
            >
                <TabItem
                    message="TRAININGS_NAME"
                    url="TRAININGS"
                />
                <TabItem
                    message="TRAININGS_WHITE_LIST_NAME"
                    url="WHITE_LIST"
                />
            </TabList>

            {isTrainings && <TrainingContent
                type={type}
                isCreating={isCreating}
                setCreating={setCreating}
            />}
            {isWhiteList && <WhiteListContent
                type={type}
                isCreating={isCreating}
                setCreating={setCreating}
            />}
        </Content>
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TrainingContainer.propTypes = {
    type : PropTypes.string.isRequired,
};

export default TrainingContainer;
