import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Training Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function TrainingEdit(props) {
    const { open, isCopy, elemID, activityID, onClose, onSubmit } = props;

    const { statuses } = Store.useState("training");
    const { editElem } = Store.useAction("training");


    // The Initial Data
    const initialData = {
        trainingID : 0,
        activityID : 0,
        name       : "",
        fromDate   : "",
        toDate     : "",
        status     : "Active",
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        await editElem({
            ...data,
            trainingID : isCopy ? 0 : (elemID || 0),
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("training", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("training", open, elemID, { activityID }, setElem);


    // Generates the Title
    const title = React.useMemo(() => {
        if (isCopy) {
            return "TRAININGS_COPY_TITLE";
        }
        if (elemID) {
            return "TRAININGS_EDIT_TITLE";
        }
        return "TRAININGS_CREATE_TITLE";
    }, [ isCopy, elemID ]);


    // Do the Render
    return <EditDialog
        open={open}
        icon="training"
        title={title}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="date"
            name="fromDate"
            label="GENERAL_FROM_DATE"
            value={data.fromDate}
            error={errors.fromDate}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="date"
            name="toDate"
            label="GENERAL_TO_DATE"
            value={data.toDate}
            error={errors.toDate}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
TrainingEdit.propTypes = {
    open       : PropTypes.bool.isRequired,
    isCopy     : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    elemID     : PropTypes.number,
    activityID : PropTypes.number,
};

export default TrainingEdit;
